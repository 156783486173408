import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {getEvents} from '../../../selectors/events'
import {ListItemContainer} from '../../event-container/list-item-container'
import {DesktopItem} from '../item'
import s from './featured-list.scss'

export const FeaturedList = () => {
  const {t} = useTranslation()
  const [featuredEvent] = useWidgetState(getEvents)

  return (
    <div className={s.container}>
      <ListItemContainer event={featuredEvent} hideDivider>
        {hovered => <DesktopItem hovered={hovered} event={featuredEvent} t={t} />}
      </ListItemContainer>
    </div>
  )
}
