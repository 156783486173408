import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {useWidgetState} from '../../../hooks/state-provider'
import {useEventsList} from '../../../hooks/use-events-list'
import {getCompId} from '../../../selectors/component'
import {focusFirstNewEvent} from '../../../utils/a11y'
import {ListItemContainer} from '../../event-container/list-item-container'
import {LoadMoreButton} from '../../load-more-button'
import {MainListTitle} from '../../main-list-title'
import {DesktopItem} from '../item'
import {ListProps} from './interfaces'
import s from './list.scss'

export const List = ({t, narrow, mobile, listLayout}: ListProps) => {
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const {experiments} = useExperiments()

  const eventsCount = events.length
  const compId = useWidgetState(getCompId)
  const widgetPaddingSettingsEnabled = experiments.enabled(ExperimentNames.WidgetPaddingSettings)

  const style: React.CSSProperties = {
    padding: widgetPaddingSettingsEnabled ? undefined : narrow ? '0 20px' : '0 30px',
    overflowY: narrow && !mobile ? 'scroll' : 'hidden',
    height: narrow && !mobile ? '100%' : 'auto',
  }
  const className = classNames(
    s.container,
    s.scrollBarColor,
    mobile && !listLayout ? s.cardContainer : s.listContainer,
    {[s.padding]: widgetPaddingSettingsEnabled},
  )

  useEffect(() => {
    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId})
  }, [eventsCount])

  return (
    <div className={className} style={style} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <ListItemContainer
            key={event.id}
            event={event}
            showTopDivider={widgetPaddingSettingsEnabled && index === 0}
            hideDivider={!widgetPaddingSettingsEnabled && events.length - 1 === index && !hasMore}
          >
            {hovered => <DesktopItem event={event} hovered={hovered} t={t} />}
          </ListItemContainer>
        ))}
      </ul>
      <div className={!widgetPaddingSettingsEnabled || hasMore || moreLoading ? s.loadMoreWrapper : null}>
        <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
      </div>
    </div>
  )
}
